/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/globals.css"
require("@fontsource/montserrat")
require("@fontsource/montserrat/300.css")
require("@fontsource/montserrat/400.css")
require("@fontsource/montserrat/500.css")
require("@fontsource/montserrat/600.css")
require("@fontsource/montserrat/700.css")
